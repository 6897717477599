:root {
  /* * * COLORS * * */

  /* Neutral */
  --color-neutral-white: 255, 255, 255;
  --color-neutral-grey: 128, 128, 128;
  --color-neutral-black: 0, 0, 0;
  --color-neutral-surface--dark: 18, 18, 18;
  --color-neutral-surface--sticker: 36, 36, 36;

  /* Secondary brands */
  --color-cinema: 61, 134, 246;
  --color-kids: 255, 179, 0;
  --color-sport: 194, 48, 168;
  --color-series: 253, 16, 30;
  --color-docs: 127, 42, 141;

  /* Context colors */
  /* @deprecated Now using dive-tokens instead */
  --color-live: 216, 12, 46;

  /* Semantic */
  --color-error: 235, 63, 69;
  --color-warning: 247, 87, 18;
  --color-success: 11, 158, 101;
  --color-info: 16, 140, 255;
  --color-hint: 153, 153, 153;

  /* ButtonIcon */
  --color-button-icon-episode-list-bg: 36, 36, 36;

  /* Toggle */
  /* @deprecated Now using dive-tokens instead */
  --color-toggle-knob: 255, 255, 255;

  /* Sticker */
  --color-surface-sticker-free-to-air: 36, 36, 36;
  --color-surface-sticker-date: 36, 36, 36;
  --color-surface-sticker-last-days: 216, 12, 46;
  --color-surface-sticker-live: 216, 12, 46;
  --color-surface-sticker-offered-content: 255, 255, 255;
  --color-surface-sticker-order: 255, 255, 255;

  --color-text-sticker-get: 255, 255, 255;
  --color-text-sticker-subscribe: 255, 255, 255;
  --color-text-sticker-free-to-air: 255, 255, 255;
  --color-text-sticker-date: 255, 255, 255;
  --color-text-sticker-last-days: 255, 255, 255;
  --color-text-sticker-live: 255, 255, 255;
  --color-text-sticker-offered-content: 0, 0, 0;
  --color-text-sticker-order: 0, 0, 0;

  --color-icon-sticker: 0, 0, 0;
  --color-tv-icon-sticker: 0, 0, 0;
}
:root,
:root [data-theme~='dark'] {
  --color-secondary: 36, 36, 36;
  --color-secondary--hover: 58, 58, 58;
  --color-secondary--pressed: 24, 24, 24;

  /* Content */
  --color-content-primary: 24, 24, 24;
  --color-content-secondary: 36, 36, 36;
  --color-content-tertiary: 54, 54, 54;
  --color-content-background: 14, 14, 14;
  --color-content-surface: 24, 24, 24;
  --color-content-surface--reverse: 58, 58, 58;

  /* Border */
  --color-border-base: 36, 36, 36;
  --color-border--opaque: 54, 54, 54;
  --color-border--active: 255, 255, 255;

  /* Text */
  --color-text: 255, 255, 255;
  --color-text--reverse: 0, 0, 0;
  /* @deprecated Now using dt-theme-link-link-default instead */
  --color-text--link: 235, 235, 235;
  --color-text--hover: 245, 245, 245;
  --color-text--pressed: 214, 214, 214;

  /* Icon */
  /* @deprecated Now using dive-tokens instead */
  --color-avatar-icon: 0, 0, 0;

  /* Selection Controls */
  /* @deprecated Now using dive-tokens instead */
  --color-selection: 255, 255, 255;
  --color-selection--disabled: 255, 255, 255;

  /* Tooltip */
  --color-tooltip-bg: 235, 235, 235;
  --color-tooltip-text: 0, 0, 0;

  /* List */
  /* @deprecated Now using dive-tokens instead */
  --color-list-surface: 24, 24, 24;
  --color-list-border: var(--color-list-surface);
  --color-list-border--disabled: 77, 77, 77;
  --color-list-divider: 36, 36, 36;

  /* Alert */
  --color-alert-bg: var(--color-content-surface);
  --color-alert-bg--error: 58, 24, 25;
  --color-alert-bg--warning: 61, 29, 15;
  --color-alert-bg--success: 13, 43, 31;
  --color-alert-bg--info: 13, 40, 62;
  --color-alert-bg--hint: 39, 39, 39;

  /* ButtonIcon */
  --color-button-icon-bg: 54, 54, 54;
  --color-button-icon-bg--hover: 74, 74, 74;
  --color-button-icon-bg-border: 36, 36, 36;

  /* ButtonLogo */
  /* @deprecated Now using dive-tokens instead */
  --color-button-logo-bg: var(--color-content-secondary);

  /* NavItem/NavTab (Header) */
  --color-nav-item-fg: var(--color-text), 1;
  --color-nav-item-bg--selected: 54, 54, 54;

  /* Season Selector */
  /* @deprecated Now using dive-tokens instead */
  --color-season-selector-bg: var(--color-secondary);
  --color-season-selector-bg--selected: 255, 255, 255;

  /* Toggle */
  /* @deprecated Now using dive-tokens instead */
  --color-toggle-bg--off: 214, 214, 214;
  --color-toggle-label: 255, 255, 255;

  /* Avatar */
  /* @deprecated Now using dive-tokens instead */
  --color-avatar-surface-add: 24, 24, 24;

  /* Dropdown */
  /* @deprecated Now using dive-tokens instead */
  --color-dropdown-surface: 24, 24, 24;
}
:root,
:root [data-theme~='mycanal'] {
  --color-primary: 236, 54, 84;
  --color-primary--hover: 238, 74, 101;
  --color-primary--pressed: 212, 49, 76;

  /* Text */
  --color-primary-fg: 255, 255, 255;
  --color-primary-fg--button: 255, 255, 255;

  /* Selection Controls */
  /* @deprecated Now using dive-tokens instead */
  --color-selection--error: 236, 54, 84;

  /* Toggle */
  /* @deprecated Now using dive-tokens instead */
  --color-toggle-bg--on: 236, 54, 84;

  /* Icons */
  --fill-progress-completed-checkmark: 236, 54, 84;

  /* Sticker */
  --color-surface-sticker-get: 236, 54, 84;
  --color-surface-sticker-subscribe: 236, 54, 84;
}
:root,
:root [data-theme~='myCANAL'] {
  --dt-theme-tv-gradient-tabs-end: var(--dt-global-palette-white-opacity-0);
  --dt-theme-tv-gradient-tabs-start: var(--dt-global-palette-white-opacity-05);
  --dt-theme-tv-text-text-input-text-input-cursor: var(--dt-global-palette-black-100);
  --dt-theme-tv-text-text-input-text-input-sublabel-disabled: var(--dt-global-palette-black-opacity-55);
  --dt-theme-tv-text-text-input-text-input-sublabel-focus: var(--dt-global-palette-black-opacity-80);
  --dt-theme-tv-text-text-input-text-input-sublabel: var(--dt-global-palette-black-opacity-55);
  --dt-theme-tv-text-text-input-text-input-label-disabled: var(--dt-global-palette-black-opacity-55);
  --dt-theme-tv-text-text-input-text-input-label-active: var(--dt-global-palette-black-100);
  --dt-theme-tv-text-text-input-text-input-label-pressed: var(--dt-global-palette-black-opacity-55);
  --dt-theme-tv-text-text-input-text-input-label-focus: var(--dt-global-palette-black-100);
  --dt-theme-tv-text-text-input-text-input-label: var(--dt-global-palette-black-100);
  --dt-theme-tv-text-text-input-text-input-placeholder-focus: var(--dt-global-palette-black-opacity-55);
  --dt-theme-tv-text-text-input-text-input-placeholder: var(--dt-global-palette-black-opacity-55);
  --dt-theme-tv-text-sticker-sticker-subscribe: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-sticker-sticker-get: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-sticker-sticker-order: var(--dt-global-palette-black-100);
  --dt-theme-tv-text-sticker-sticker-offered-content: var(--dt-global-palette-black-100);
  --dt-theme-tv-text-sticker-sticker-live: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-sticker-sticker-last-days: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-sticker-sticker-theatrical-release: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-sticker-sticker-coming-soon: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-sticker-sticker-duration: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-sticker-sticker-date: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-sticker-sticker-free-to-air: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-season-selector-season-selector-description: var(--dt-global-palette-white-opacity-55);
  --dt-theme-tv-text-season-selector-season-selector-select: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-season-selector-season-selector-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-season-selector-season-selector-default: var(--dt-global-palette-white-opacity-55);
  --dt-theme-tv-text-password-input-password-input-hint: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-password-input-password-input-filled: var(--dt-global-palette-grey-85);
  --dt-theme-tv-text-offer-tag-offer-tag: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-nav-item-tvos-nav-item-tvos-select: var(--dt-global-palette-white-opacity-60);
  --dt-theme-tv-text-nav-item-tvos-nav-item-tvos-focus: var(--dt-global-palette-black-100);
  --dt-theme-tv-text-nav-item-tvos-nav-tab-tvos-default: var(--dt-global-palette-white-opacity-60);
  --dt-theme-tv-text-nav-item-nav-item-select: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-nav-item-nav-item-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-nav-item-nav-item-default: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-media-top-10-ranking-media-top-10-ranking-number: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-media-strate-tiles-media-strate-subtitle: var(--dt-global-palette-white-opacity-70);
  --dt-theme-tv-text-media-strate-tiles-media-strate-title: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-media-block-media-block-description-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-media-block-media-block-subtitle-focus: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-text-media-block-media-block-description: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-media-block-media-block-subtitle: var(--dt-global-palette-white-opacity-40);
  --dt-theme-tv-text-media-block-media-block-title-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-media-block-media-block-title: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-list-timeline-vote-list-timeline-vote-label: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-list-timeline-vote-list-timeline-vote-title: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-list-statistics-list-statistics-secondary-data-focus: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-list-statistics-list-statistics-primary-data-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-list-statistics-list-statistics-subtitle-focus: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-list-statistics-list-statistics-title-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-list-statistics-list-statistics-secondary-data: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-text-list-statistics-list-statistics-primary-data: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-list-statistics-list-statistics-subtitle: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-text-list-statistics-list-statistics-title: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-list-ranking-list-ranking-point-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-list-ranking-list-ranking-number-focus: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-text-list-ranking-list-ranking-title-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-list-ranking-list-ranking-point: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-list-ranking-list-ranking-number: var(--dt-global-palette-white-opacity-30);
  --dt-theme-tv-text-list-ranking-list-ranking-title: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-list-list-group-description: var(--dt-global-palette-grey-50);
  --dt-theme-tv-text-list-list-group-subtitle: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-list-list-group-title: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-list-list-row-promotion-focus: var(--dt-global-palette-white-opacity-70);
  --dt-theme-tv-text-list-list-row-promotion-default: var(--dt-global-palette-white-opacity-55);
  --dt-theme-tv-text-list-list-row-price-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-list-list-row-price-default: var(--dt-global-palette-white-opacity-70);
  --dt-theme-tv-text-list-list-row-sublabel-focus: var(--dt-global-palette-white-opacity-70);
  --dt-theme-tv-text-list-list-row-sublabel-default: var(--dt-global-palette-white-opacity-55);
  --dt-theme-tv-text-list-list-row-label-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-list-list-row-label-default: var(--dt-global-palette-white-opacity-70);
  --dt-theme-tv-text-list-list-row-subtitle-focus: var(--dt-global-palette-white-opacity-70);
  --dt-theme-tv-text-list-list-row-subtitle-default: var(--dt-global-palette-white-opacity-55);
  --dt-theme-tv-text-list-list-row-title-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-list-list-row-title-default: var(--dt-global-palette-white-opacity-70);
  --dt-theme-tv-text-input-field-alert-input-field-alert: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-input-field-input-field-label: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-input-field-input-field-prefix: var(--dt-global-palette-white-opacity-55);
  --dt-theme-tv-text-header-header-security: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-tabs-tabs-select: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-tabs-tabs-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-tabs-tabs-default: var(--dt-global-palette-white-opacity-55);
  --dt-theme-tv-text-episode-list-episode-list-mention-focus: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-text-episode-list-episode-list-hour-focus: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-text-episode-list-episode-list-body-focus: var(--dt-global-palette-white-opacity-70);
  --dt-theme-tv-text-episode-list-episode-list-label-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-episode-list-episode-list-title-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-episode-list-episode-list-mention: var(--dt-global-palette-white-opacity-40);
  --dt-theme-tv-text-episode-list-episode-list-hour: var(--dt-global-palette-white-opacity-40);
  --dt-theme-tv-text-episode-list-episode-list-body: var(--dt-global-palette-white-opacity-60);
  --dt-theme-tv-text-episode-list-episode-list-label: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-episode-list-episode-list-title: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-card-timeline-facts-body-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-card-timeline-facts-body-default: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-card-item-countdown-v2-card-item-countdown: var(--dt-global-palette-black-100);
  --dt-theme-tv-text-card-item-countdown-card-item-countdown: var(--dt-global-palette-pink-55);
  --dt-theme-tv-text-card-item-advantages-card-item-price-tag: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-card-item-advantages-card-item-promotion-tag: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-card-tile-card-tile-body: var(--dt-global-palette-white-opacity-55);
  --dt-theme-tv-text-card-tile-card-tile-title: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-card-infobanner-card-infobanner-body: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-card-infobanner-card-infobanner-title: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-card-game-rating-card-game-rating-rate-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-card-game-rating-card-game-rating-countdown-focus: var(--dt-global-palette-white-opacity-90);
  --dt-theme-tv-text-card-game-rating-card-game-rating-description-focus: var(--dt-global-palette-white-opacity-70);
  --dt-theme-tv-text-card-game-rating-card-game-rating-title-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-card-game-rating-card-game-rating-rate: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-card-game-rating-card-game-rating-countdown: var(--dt-global-palette-white-opacity-70);
  --dt-theme-tv-text-card-game-rating-card-game-rating-description: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-text-card-game-rating-card-game-rating-title: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-card-detailed-tile-card-tile-link-description: var(--dt-global-palette-white-opacity-55);
  --dt-theme-tv-text-card-detailed-tile-card-tile-body: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-card-detailed-tile-card-tile-title: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-button-button-detailed-page-secondary-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-button-button-detailed-page-secondary: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-button-button-detailed-page-tertiary-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-button-button-detailed-page-tertiary: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-button-button-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-button-button-regular: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-button-button-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-text-button-button: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-avatar-strate-tiles-avatar-strate-tiles-title: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-avatar-avatar-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-avatar-avatar: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-text-alert-box-alert-box: var(--dt-global-palette-white-100);
  --dt-theme-tv-text-alert-banner-alert-banner: var(--dt-global-palette-white-100);
  --dt-theme-tv-surface-text-input-text-input-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-surface-text-input-text-input-disable: var(--dt-global-palette-white-opacity-90);
  --dt-theme-tv-surface-text-input-text-input-default: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-surface-sticker-sticker-subscribe: var(--dt-global-palette-pink-55);
  --dt-theme-tv-surface-sticker-sticker-get: var(--dt-global-palette-pink-55);
  --dt-theme-tv-surface-sticker-sticker-order: var(--dt-global-palette-white-100);
  --dt-theme-tv-surface-sticker-sticker-offered-content: var(--dt-global-palette-white-100);
  --dt-theme-tv-surface-sticker-sticker-live: var(--dt-global-palette-red-55);
  --dt-theme-tv-surface-sticker-sticker-last-days: var(--dt-global-palette-red-55);
  --dt-theme-tv-surface-sticker-sticker-duration: var(--dt-global-palette-grey-85-opacity-60);
  --dt-theme-tv-surface-sticker-sticker-date: var(--dt-global-palette-grey-85-opacity-60);
  --dt-theme-tv-surface-sticker-sticker-coming-soon: var(--dt-global-palette-grey-85-opacity-60);
  --dt-theme-tv-surface-sticker-sticker-theatrical-release: var(--dt-global-palette-grey-85-opacity-60);
  --dt-theme-tv-surface-sticker-sticker-free-to-air: var(--dt-global-palette-grey-85-opacity-60);
  --dt-theme-tv-surface-progressbar-statistics-progressbar-statistics-active: var(--dt-global-palette-red-55);
  --dt-theme-tv-surface-progressbar-statistics-progressbar-statistics-default: var(--dt-global-palette-white-100);
  --dt-theme-tv-surface-progressbar-progressbar-circle: var(--dt-global-palette-pink-55);
  --dt-theme-tv-surface-progressbar-progressbar-background: var(--dt-global-palette-white-opacity-20);
  --dt-theme-tv-surface-progressbar-progressbar-live: var(--dt-global-palette-red-55);
  --dt-theme-tv-surface-progressbar-progressbar-on-going: var(--dt-global-palette-white-100);
  --dt-theme-tv-surface-password-input-password-input-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-surface-password-input-password-input-active: var(--dt-global-palette-white-100);
  --dt-theme-tv-surface-password-input-password-input: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-surface-nav-user-menu-nav-user-menu-focus: var(--dt-global-palette-pink-55);
  --dt-theme-tv-surface-nav-user-menu-nav-user-menu-default: var(--dt-global-palette-grey-85);
  --dt-theme-tv-surface-list-timeline-vote-list-timeline-vote-selected: var(--dt-global-palette-pink-55);
  --dt-theme-tv-surface-list-timeline-vote-list-timeline-vote-result: var(--dt-global-palette-grey-80);
  --dt-theme-tv-surface-list-statistics-list-statistics-focus: var(--dt-global-palette-grey-85);
  --dt-theme-tv-surface-list-statistics-list-statistics: var(--dt-global-palette-grey-90);
  --dt-theme-tv-surface-list-ranking-list-ranking-focus: var(--dt-global-palette-grey-85);
  --dt-theme-tv-surface-list-ranking-list-ranking-highlight: var(--dt-global-palette-grey-85);
  --dt-theme-tv-surface-list-ranking-list-ranking: var(--dt-global-palette-grey-90);
  --dt-theme-tv-surface-list-list-focus: var(--dt-global-palette-pink-55);
  --dt-theme-tv-surface-list-list: var(--dt-global-palette-grey-85);
  --dt-theme-tv-surface-card-timeline-facts-card-timeline-facts-focus: var(--dt-global-palette-grey-90);
  --dt-theme-tv-surface-card-timeline-facts-card-timeline-facts: var(--dt-global-palette-grey-90);
  --dt-theme-tv-surface-card-item-advantages-card-item-advantages-price-tag: var(--dt-global-palette-green-95);
  --dt-theme-tv-surface-card-item-advantages-card-item-advantages-promotion-tag: var(--dt-global-palette-blue-30);
  --dt-theme-tv-surface-card-item-countdown-v2-card-item-countdown: var(--dt-global-palette-yellow-50);
  --dt-theme-tv-surface-card-infobanner-card-infobanner: var(--dt-global-palette-black-100);
  --dt-theme-tv-surface-card-tile-card-tile: var(--dt-global-palette-grey-90);
  --dt-theme-tv-surface-card-detailed-card-detailed: var(--dt-global-palette-grey-90);
  --dt-theme-tv-surface-card-game-rating-card-game-rating-focus: var(--dt-global-palette-grey-85);
  --dt-theme-tv-surface-card-game-rating-card-game-rating: var(--dt-global-palette-grey-90);
  --dt-theme-tv-surface-button-logo-surface-button-logo-bullets: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-surface-alert-banner-alert-banner: var(--dt-global-palette-grey-90);
  --dt-theme-tv-surface-alert-alert: var(--dt-global-palette-grey-90);
  --dt-theme-tv-icon-sticker-icon: var(--dt-global-palette-black-100);
  --dt-theme-tv-icon-password-input-icon-filled: var(--dt-global-palette-grey-85);
  --dt-theme-tv-icon-password-input-icon-check: var(--dt-global-palette-green-35);
  --dt-theme-tv-icon-password-input-icon-cross: var(--dt-global-palette-red-35);
  --dt-theme-tv-icon-nav-user-menu-icon-alert-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-nav-user-menu-icon-alert-default: var(--dt-global-palette-pink-55);
  --dt-theme-tv-icon-nav-user-menu-icon-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-nav-user-menu-icon: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-live-grid-icon: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-list-icon-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-list-icon: var(--dt-global-palette-white-opacity-70);
  --dt-theme-tv-icon-header-header-icon: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-episode-list-icon-check: var(--dt-global-palette-pink-55);
  --dt-theme-tv-icon-episode-list-icon: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-card-tile-icon: var(--dt-global-palette-pink-55);
  --dt-theme-tv-icon-card-item-price-tag-icon: var(--dt-global-palette-green-50);
  --dt-theme-tv-icon-card-item-countdown-v2-icon: var(--dt-global-palette-black-100);
  --dt-theme-tv-icon-card-item-countdown-icon: var(--dt-global-palette-pink-55);
  --dt-theme-tv-icon-card-item-promotion-tag-icon: var(--dt-global-palette-blue-10);
  --dt-theme-tv-icon-card-infobanner-icon: var(--dt-global-palette-grey-05);
  --dt-theme-tv-icon-button-icon-icon-alert-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-button-icon-icon-alert-default: var(--dt-global-palette-pink-55);
  --dt-theme-tv-icon-button-icon-icon-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-button-icon-icon: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-button-icon-regular: var(--dt-global-palette-white-opacity-80);
  --dt-theme-tv-icon-button-icon-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-icon-button-icon-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-button-icon: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-avatar-icon: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-alert-icon: var(--dt-global-palette-white-100);
  --dt-theme-tv-icon-icon: var(--dt-global-palette-white-100);
  --dt-theme-tv-border-text-input-field-text-input-field: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-border-offer-tag-offer-tag: var(--dt-global-palette-white-100);
  --dt-theme-tv-border-nav-user-menu-nav-user-menu-select: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-border-list-list: var(--dt-global-palette-white-opacity-30);
  --dt-theme-tv-border-card-infobanner-card-infobanner: var(--dt-global-palette-grey-85);
  --dt-theme-tv-border-button-detail-page-hind-button-detail-page-hind: var(--dt-global-palette-grey-80);
  --dt-theme-tv-border-button-icon-button-icon-selected: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-border-avatar-avatar-selected: var(--dt-global-palette-white-opacity-50);
  --dt-theme-tv-border-focus: var(--dt-global-palette-pink-55);
  --dt-theme-tv-background-season-selector-season-selector-selected: var(--dt-global-palette-grey-85);
  --dt-theme-tv-background-season-selector-season-selector-focus: var(--dt-global-palette-pink-55);
  --dt-theme-tv-background-nav-item-tvos-nav-tab-tvos-select-tvos: var(--dt-global-palette-system-system-grey-10);
  --dt-theme-tv-background-nav-item-tvos-nav-tab-tvos-focus: var(--dt-global-palette-white-100);
  --dt-theme-tv-background-nav-item-nav-item-select: var(--dt-global-palette-grey-80);
  --dt-theme-tv-background-nav-item-nav-item-focus: var(--dt-global-palette-pink-55);
  --dt-theme-tv-background-load-media-placeholder-load-media-placeholder: var(--dt-global-palette-grey-85);
  --dt-theme-tv-background-list-timeline-vote-list-timeline-vote-error: var(--dt-global-palette-grey-85);
  --dt-theme-tv-background-list-timeline-vote-list-timeline-vote-success: var(--dt-global-palette-green-50);
  --dt-theme-tv-background-list-timeline-vote-list-timeline-vote-inactive: var(--dt-global-palette-grey-85);
  --dt-theme-tv-background-list-timeline-vote-list-timeline-vote-focus: var(--dt-global-palette-grey-85);
  --dt-theme-tv-background-list-timeline-vote-list-timeline-vote-default: var(--dt-global-palette-grey-90);
  --dt-theme-tv-background-icon-live-grid-icon-live-grid: var(--dt-global-palette-black-opacity-40);
  --dt-theme-tv-background-icon-card-infobanner-icon-card-infobanner: var(--dt-global-palette-grey-90);
  --dt-theme-tv-background-icon-alert-icon-alert-hint: var(--dt-global-palette-grey-45);
  --dt-theme-tv-background-icon-alert-icon-alert-info: var(--dt-global-palette-blue-10);
  --dt-theme-tv-background-icon-alert-icon-alert-warning: var(--dt-global-palette-orange-50);
  --dt-theme-tv-background-icon-alert-icon-alert-success: var(--dt-global-palette-green-50);
  --dt-theme-tv-background-icon-alert-icon-alert-error: var(--dt-global-palette-red-35);
  --dt-theme-tv-background-icon-edit: var(--dt-global-palette-grey-35);
  --dt-theme-tv-background-icon-kids: var(--dt-global-palette-blue-10);
  --dt-theme-tv-background-header-header-tvos: var(--dt-global-palette-black-opacity-70);
  --dt-theme-tv-background-tabs-tabs-focus: var(--dt-global-palette-pink-55);
  --dt-theme-tv-background-tabs-tabs-default: var(--dt-global-palette-grey-95);
  --dt-theme-tv-background-episode-list-icon-episode-list-unavailable: var(--dt-global-palette-black-opacity-50);
  --dt-theme-tv-background-episode-list-icon-episode-list-icon-check: var(--dt-global-palette-white-100);
  --dt-theme-tv-background-episode-list-icon-episode-list-icon-button-icon: var(--dt-global-palette-grey-85-opacity-70);
  --dt-theme-tv-background-button-logo-button-logo: var(--dt-global-palette-grey-85);
  --dt-theme-tv-background-button-icon-button-icon-large-focus: var(--dt-global-palette-pink-55);
  --dt-theme-tv-background-button-icon-button-icon-large-selected: var(--dt-global-palette-grey-85-opacity-70);
  --dt-theme-tv-background-button-icon-button-icon-large-default: var(--dt-global-palette-grey-85-opacity-70);
  --dt-theme-tv-background-button-icon-button-icon-focus: var(--dt-global-palette-pink-55);
  --dt-theme-tv-background-button-icon-button-icon-selected: var(--dt-global-palette-grey-85);
  --dt-theme-tv-background-button-icon-button-icon-default: var(--dt-global-palette-grey-85);
  --dt-theme-tv-background-button-button-focus: var(--dt-global-palette-pink-55);
  --dt-theme-tv-background-button-button-disabled: var(--dt-global-palette-grey-85-opacity-50);
  --dt-theme-tv-background-button-button-default: var(--dt-global-palette-grey-85);
  --dt-theme-tv-background-avatar-menu-tvos-avatar-menu-tvos: var(--dt-global-palette-white-100);
  --dt-theme-tv-background-avatar-avatar: var(--dt-global-palette-grey-90);
  --dt-theme-tv-background-alert-banner-alert-banner: var(--dt-global-palette-black-opacity-85);
  --dt-theme-gradient-media-overlay-gradient-gradient-end: var(--dt-global-palette-black-opacity-0);
  --dt-theme-gradient-media-overlay-gradient-gradient-start: var(--dt-global-palette-black-opacity-20);
  --dt-theme-gradient-media-logo-gradient-gradient-end: var(--dt-global-palette-black-opacity-0);
  --dt-theme-gradient-media-logo-gradient-gradient-start: var(--dt-global-palette-black-opacity-30);
  --dt-theme-gradient-header-nav-left-gradient-gradient-end: var(--dt-global-palette-grey-95-opacity-0);
  --dt-theme-gradient-header-nav-left-gradient-gradient-start: var(--dt-global-palette-grey-95);
  --dt-theme-gradient-header-nav-right-gradient-gradient-end: var(--dt-global-palette-grey-95-opacity-0);
  --dt-theme-gradient-header-nav-right-gradient-gradient-start: var(--dt-global-palette-grey-95);
  --dt-theme-gradient-header-gradient-gradient-end: var(--dt-global-palette-grey-95-opacity-0);
  --dt-theme-gradient-header-gradient-gradient-start: var(--dt-global-palette-grey-95-opacity-50);
  --dt-theme-text-top-app-bar-top-app-bar-text-accent: var(--dt-global-palette-pink-55);
  --dt-theme-text-top-app-bar-top-app-bar-text: var(--dt-global-palette-white-100);
  --dt-theme-text-toggle-switch-toggle-switch-sublabel-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-toggle-switch-toggle-switch-sublabel: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-toggle-switch-toggle-switch-label-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-toggle-switch-toggle-switch-label: var(--dt-global-palette-white-100);
  --dt-theme-text-textarea-input-field-textarea-input-field-label-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-textarea-input-field-textarea-input-field-label: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-textarea-input-field-textarea-input-field-hint-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-textarea-input-field-textarea-input-field-hint: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-textarea-input-field-textarea-input-field-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-textarea-input-field-textarea-input-field-filled: var(--dt-global-palette-white-100);
  --dt-theme-text-textarea-input-field-textarea-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-text-textarea-input-field-textarea-input-field: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-text-input-field-text-input-field-label: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-text-input-field-text-input-field-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-text-input-field-text-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-text-text-input-field-text-input-field: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-tooltip-tooltip-link-pressed: var(--dt-global-palette-grey-90);
  --dt-theme-text-tooltip-tooltip-link-hover: var(--dt-global-palette-grey-75);
  --dt-theme-text-tooltip-tooltip-link-default: var(--dt-global-palette-grey-85);
  --dt-theme-text-tooltip-tooltip: var(--dt-global-palette-black-100);
  --dt-theme-text-sticker-sticker-subscribe: var(--dt-global-palette-white-100);
  --dt-theme-text-sticker-sticker-get: var(--dt-global-palette-white-100);
  --dt-theme-text-sticker-sticker-order: var(--dt-global-palette-black-100);
  --dt-theme-text-sticker-sticker-offered-content: var(--dt-global-palette-black-100);
  --dt-theme-text-sticker-sticker-live: var(--dt-global-palette-white-100);
  --dt-theme-text-sticker-sticker-last-days: var(--dt-global-palette-white-100);
  --dt-theme-text-sticker-sticker-theatrical-release: var(--dt-global-palette-white-100);
  --dt-theme-text-sticker-sticker-coming-soon: var(--dt-global-palette-white-100);
  --dt-theme-text-sticker-sticker-duration: var(--dt-global-palette-white-100);
  --dt-theme-text-sticker-sticker-date: var(--dt-global-palette-white-100);
  --dt-theme-text-sticker-sticker-free-to-air: var(--dt-global-palette-white-100);
  --dt-theme-text-select-input-option-field-select-input-option-field: var(--dt-global-palette-white-100);
  --dt-theme-text-select-input-field-select-input-field-label-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-select-input-field-select-input-field-label: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-select-input-field-select-input-field-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-select-input-field-select-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-text-select-input-field-select-input-field: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-segmented-control-segmented-control-accent: var(--dt-global-palette-pink-55);
  --dt-theme-text-segmented-control-segmented-control: var(--dt-global-palette-white-100);
  --dt-theme-text-season-selector-season-selector-selected: var(--dt-global-palette-black-100);
  --dt-theme-text-season-selector-season-selector: var(--dt-global-palette-grey-10);
  --dt-theme-text-search-field-search-field-cursor: var(--dt-global-palette-pink-55);
  --dt-theme-text-search-field-search-field-accent: var(--dt-global-palette-pink-55);
  --dt-theme-text-search-field-search-field-filled: var(--dt-global-palette-white-100);
  --dt-theme-text-search-field-search-field: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-radio-button-radio-sublabel-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-radio-button-radio-sublabel: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-radio-button-radio-label-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-radio-button-radio-label: var(--dt-global-palette-white-100);
  --dt-theme-text-profile-card-accent: var(--dt-global-palette-pink-55);
  --dt-theme-text-password-input-field-password-input-field-label: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-password-input-field-password-input-field-hint: var(--dt-global-palette-white-100);
  --dt-theme-text-password-input-field-password-input-field-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-password-input-field-password-input-field-filled: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-password-input-field-password-input-field-active: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-password-input-field-password-input-field: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-offer-card-offer-card-price-advantage: var(--dt-global-palette-green-50);
  --dt-theme-text-offer-card-offer-card-price-description: var(--dt-global-palette-grey-85);
  --dt-theme-text-offer-card-offer-card-price: var(--dt-global-palette-black-100);
  --dt-theme-text-offer-card-offer-card-note: var(--dt-global-palette-black-opacity-55);
  --dt-theme-text-offer-card-offer-card-body: var(--dt-global-palette-black-100);
  --dt-theme-text-offer-card-offer-card-title: var(--dt-global-palette-black-100);
  --dt-theme-text-offer-tag-badge: var(--dt-global-palette-white-100);
  --dt-theme-text-number-access-input-field-number-access-input-field-hint: var(--dt-global-palette-white-100);
  --dt-theme-text-number-access-input-field-number-access-input-field-disabled: var(
    --dt-global-palette-white-opacity-50
  );
  --dt-theme-text-number-access-input-field-number-access-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-text-number-access-input-field-number-access-input-field: var(--dt-global-palette-white-100);
  --dt-theme-text-modal-modal-text-accent: var(--dt-global-palette-pink-55);
  --dt-theme-text-modal-modal-text: var(--dt-global-palette-white-100);
  --dt-theme-text-mm-yy-ccv-input-field-mm-yy-ccv-input-field-label: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-mm-yy-ccv-input-field-mm-yy-ccv-input-field-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-mm-yy-ccv-input-field-mm-yy-ccv-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-text-mm-yy-ccv-input-field-mm-yy-ccv-input-field: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-media-top-10-ranking-media-top-10-ranking-number: var(--dt-global-palette-white-100);
  --dt-theme-text-media-strate-tiles-media-strate-tiles-subtitle: var(--dt-global-palette-white-opacity-70);
  --dt-theme-text-media-strate-tiles-media-strate-tiles-title: var(--dt-global-palette-white-100);
  --dt-theme-text-media-block-media-block-inside-description: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-media-block-media-block-description: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-media-block-media-block-inside-subtitle: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-media-block-media-block-subtitle: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-media-block-media-block-inside-title: var(--dt-global-palette-white-100);
  --dt-theme-text-media-block-media-block-title: var(--dt-global-palette-white-100);
  --dt-theme-text-list-timeline-vote-list-timeline-vote-label: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-list-timeline-vote-list-timeline-vote-title: var(--dt-global-palette-white-100);
  --dt-theme-text-list-statistics-list-statistics-secondary-data: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-list-statistics-list-statistics-primary-data: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-list-statistics-list-statistics-subtitle: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-list-statistics-list-statistics-title: var(--dt-global-palette-white-100);
  --dt-theme-text-list-row-link-disabled: var(--dt-global-palette-pink-55-opacity-05);
  --dt-theme-text-list-row-list-row-promotion-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-list-row-list-row-price-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-list-row-list-row-label-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-list-row-list-row-subtitle-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-list-row-list-row-title-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-list-row-link: var(--dt-global-palette-pink-55);
  --dt-theme-text-list-row-list-row-promotion: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-list-row-list-row-price: var(--dt-global-palette-white-100);
  --dt-theme-text-list-row-list-row-label: var(--dt-global-palette-white-opacity-70);
  --dt-theme-text-list-row-list-row-subtitle: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-list-row-list-row-title: var(--dt-global-palette-white-100);
  --dt-theme-text-list-row-list-description: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-list-row-list-title: var(--dt-global-palette-white-100);
  --dt-theme-text-list-ranking-list-ranking-point: var(--dt-global-palette-white-100);
  --dt-theme-text-list-ranking-list-ranking-number: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-list-ranking-list-ranking-title: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-input-field-alert-input-field-alert: var(--dt-global-palette-white-100);
  --dt-theme-text-iban-input-field-iban-input-field-label: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-iban-input-field-iban-input-field-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-iban-input-field-iban-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-text-iban-input-field-iban-input-field: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-header-header-active: var(--dt-global-palette-pink-55);
  --dt-theme-text-header-header-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-header-header-default: var(--dt-global-palette-white-100);
  --dt-theme-text-tabs-tabs-accent: var(--dt-global-palette-pink-55);
  --dt-theme-text-tabs-tabs-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-tabs-tabs: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-tabs-gabarit-list-accent: var(--dt-global-palette-pink-55);
  --dt-theme-text-tabs-tabs-unselected: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-tabs-tabs-selected: var(--dt-global-palette-white-100);
  --dt-theme-text-tabs-tabs-active: var(--dt-global-palette-white-100);
  --dt-theme-text-tabs-tabs-enabled: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-footer-footer-brand: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-footer-footer-country: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-footer-footer-title: var(--dt-global-palette-white-100);
  --dt-theme-text-footer-footer-secondary: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-footer-footer-hover: var(--dt-global-palette-white-100);
  --dt-theme-text-footer-footer: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-filter-tabs-filter-tabs-selected: var(--dt-global-palette-black-100);
  --dt-theme-text-filter-tabs-filter-tabs-hover: var(--dt-global-palette-black-100);
  --dt-theme-text-filter-tabs-filter-tabs-default: var(--dt-global-palette-white-100);
  --dt-theme-text-episode-list-episode-list-description: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-download-list-download-list-subtitle-accent: var(--dt-global-palette-pink-55);
  --dt-theme-text-download-list-download-list-subtitle: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-download-list-download-list-title: var(--dt-global-palette-white-100);
  --dt-theme-text-credit-card-input-field-credit-card-input-field-label: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-credit-card-input-field-credit-card-input-field-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-credit-card-input-field-credit-card-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-text-credit-card-input-field-credit-card-input-field: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-civility-input-field-civility-input-field-hint: var(--dt-global-palette-white-100);
  --dt-theme-text-civility-input-field-civility-input-field-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-civility-input-field-civility-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-text-civility-input-field-civility-input-field: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-checkbox-checkbox-sublabel-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-checkbox-checkbox-sublabel: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-checkbox-checkbox-label-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-checkbox-checkbox-label: var(--dt-global-palette-white-100);
  --dt-theme-text-card-timeline-vote-versus-card-timeline-vote-versus-label: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-card-timeline-vote-versus-card-timeline-vote-versus-title: var(--dt-global-palette-white-100);
  --dt-theme-text-card-timeline-facts-body: var(--dt-global-palette-white-100);
  --dt-theme-text-card-tile-card-tile-link-accent: var(--dt-global-palette-pink-55);
  --dt-theme-text-card-tile-card-tile-body: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-card-tile-card-tile-title: var(--dt-global-palette-white-100);
  --dt-theme-text-card-tab-list-title: var(--dt-global-palette-white-100);
  --dt-theme-text-card-tab-card-tab: var(--dt-global-palette-white-100);
  --dt-theme-text-card-item-advantages-card-item-advantages-promotion-tag: var(--dt-global-palette-white-100);
  --dt-theme-text-card-item-advantages-card-item-advantages-price-tag: var(--dt-global-palette-white-100);
  --dt-theme-text-card-item-countdown-v2-card-item-countdown: var(--dt-global-palette-black-100);
  --dt-theme-text-card-item-countdown-card-item-countdown: var(--dt-global-palette-pink-55);
  --dt-theme-text-card-item-alert-card-item-alert-body: var(--dt-global-palette-black-opacity-70);
  --dt-theme-text-card-item-alert-card-item-alert-title: var(--dt-global-palette-black-100);
  --dt-theme-text-card-infobanner-card-infobanner-body: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-card-infobanner-card-infobanner-title: var(--dt-global-palette-white-100);
  --dt-theme-text-card-game-rating-card-game-rating-rate: var(--dt-global-palette-white-100);
  --dt-theme-text-card-game-rating-card-game-rating-countdown: var(--dt-global-palette-white-opacity-70);
  --dt-theme-text-card-game-rating-card-game-rating-description: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-card-game-rating-card-game-rating-title: var(--dt-global-palette-white-100);
  --dt-theme-text-card-detailed-tile-card-tile-link-description: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-card-detailed-tile-card-tile-body: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-card-detailed-tile-card-tile-title: var(--dt-global-palette-white-100);
  --dt-theme-text-button-tabs-button-tabs-selected: var(--dt-global-palette-white-100);
  --dt-theme-text-button-tabs-button-tabs-default: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-button-live-button-live-startover-disabled: var(--dt-global-palette-grey-55);
  --dt-theme-text-button-live-button-live-startover-active: var(--dt-global-palette-grey-25);
  --dt-theme-text-button-live-button-live-startover-hover: var(--dt-global-palette-grey-40);
  --dt-theme-text-button-live-button-live-startover-default: var(--dt-global-palette-white-100);
  --dt-theme-text-button-live-button-live-on-disabled: var(--dt-global-palette-red-25);
  --dt-theme-text-button-live-button-live-on-active: var(--dt-global-palette-white-100);
  --dt-theme-text-button-live-button-live-on-hover: var(--dt-global-palette-white-100);
  --dt-theme-text-button-live-button-live-on-default: var(--dt-global-palette-white-100);
  --dt-theme-text-button-icon-button-icon-hover: var(--dt-global-palette-white-100);
  --dt-theme-text-button-icon-button-icon: var(--dt-global-palette-white-opacity-55);
  --dt-theme-text-button-v2-button-fit-tertiary-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-button-v2-button-fit-tertiary-active: var(--dt-global-palette-grey-25);
  --dt-theme-text-button-v2-button-fit-tertiary-hover: var(--dt-global-palette-grey-10);
  --dt-theme-text-button-v2-button-fit-tertiary: var(--dt-global-palette-white-100);
  --dt-theme-text-button-v2-button-fit-secondary-disabled: var(--dt-global-palette-grey-85-opacity-50);
  --dt-theme-text-button-v2-button-fit-secondary-active: var(--dt-global-palette-grey-90);
  --dt-theme-text-button-v2-button-fit-secondary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-text-button-v2-button-fit-secondary: var(--dt-global-palette-grey-85);
  --dt-theme-text-button-v2-button-fit-primary-disabled: var(--dt-global-palette-pink-55-opacity-50);
  --dt-theme-text-button-v2-button-fit-primary-active: var(--dt-global-palette-pink-70);
  --dt-theme-text-button-v2-button-fit-primary-hover: var(--dt-global-palette-pink-40);
  --dt-theme-text-button-v2-button-fit-primary: var(--dt-global-palette-pink-55);
  --dt-theme-text-button-v2-button-ghost-tertiary-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-button-v2-button-ghost-tertiary-active: var(--dt-global-palette-grey-25);
  --dt-theme-text-button-v2-button-ghost-tertiary-hover: var(--dt-global-palette-grey-10);
  --dt-theme-text-button-v2-button-ghost-tertiary: var(--dt-global-palette-white-100);
  --dt-theme-text-button-v2-button-ghost-secondary-disabled: var(--dt-global-palette-grey-85-opacity-50);
  --dt-theme-text-button-v2-button-ghost-secondary-active: var(--dt-global-palette-grey-90);
  --dt-theme-text-button-v2-button-ghost-secondary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-text-button-v2-button-ghost-secondary: var(--dt-global-palette-grey-85);
  --dt-theme-text-button-v2-button-ghost-primary-disabled: var(--dt-global-palette-pink-55-opacity-50);
  --dt-theme-text-button-v2-button-ghost-primary-active: var(--dt-global-palette-pink-70);
  --dt-theme-text-button-v2-button-ghost-primary-hover: var(--dt-global-palette-pink-40);
  --dt-theme-text-button-v2-button-ghost-primary: var(--dt-global-palette-pink-55);
  --dt-theme-text-button-v2-button-filled-tertiary-disabled: var(--dt-global-palette-black-opacity-50);
  --dt-theme-text-button-v2-button-filled-tertiary: var(--dt-global-palette-black-100);
  --dt-theme-text-button-v2-button-filled-secondary-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-button-v2-button-filled-secondary: var(--dt-global-palette-white-100);
  --dt-theme-text-button-v2-button-filled-primary-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-button-v2-button-filled-primary: var(--dt-global-palette-white-100);
  --dt-theme-text-button-text-secondary-pressed: var(--dt-global-palette-grey-90);
  --dt-theme-text-button-text-secondary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-text-button-text-secondary-default: var(--dt-global-palette-grey-85);
  --dt-theme-text-button-text-accent-pressed: var(--dt-global-palette-pink-70);
  --dt-theme-text-button-text-accent-hover: var(--dt-global-palette-pink-40);
  --dt-theme-text-button-text-accent-default: var(--dt-global-palette-pink-55);
  --dt-theme-text-button-tertiary-tertiary: var(--dt-global-palette-white-100);
  --dt-theme-text-button-secondary-secondary: var(--dt-global-palette-white-100);
  --dt-theme-text-button-primary-primary: var(--dt-global-palette-white-100);
  --dt-theme-text-bottom-app-bar-bottom-app-bar-text-accent: var(--dt-global-palette-pink-55);
  --dt-theme-text-bottom-app-bar-bottom-app-bar-text: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-avatar-strate-tiles-avatar-strate-tiles-title: var(--dt-global-palette-white-100);
  --dt-theme-text-avatar-avatar: var(--dt-global-palette-white-100);
  --dt-theme-text-alert-box-alert-box: var(--dt-global-palette-white-100);
  --dt-theme-text-activation-card-activation-card: var(--dt-global-palette-black-100);
  --dt-theme-text-accordion-accordion-body: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-accordion-accordion-subtitle: var(--dt-global-palette-grey-35);
  --dt-theme-text-accordion-accordion-title: var(--dt-global-palette-white-100);
  --dt-theme-text-context-live: var(--dt-global-palette-red-55);
  --dt-theme-text-semantic-info: var(--dt-global-palette-blue-10);
  --dt-theme-text-semantic-success: var(--dt-global-palette-green-50);
  --dt-theme-text-semantic-warning: var(--dt-global-palette-orange-50);
  --dt-theme-text-semantic-error: var(--dt-global-palette-red-35);
  --dt-theme-text-secondary-secondary-pressed: var(--dt-global-palette-grey-90);
  --dt-theme-text-secondary-secondary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-text-secondary-secondary: var(--dt-global-palette-grey-85);
  --dt-theme-text-primary-accent-pressed: var(--dt-global-palette-pink-70);
  --dt-theme-text-primary-accent-hover: var(--dt-global-palette-pink-40);
  --dt-theme-text-primary-accent: var(--dt-global-palette-pink-55);
  --dt-theme-text-text-100: var(--dt-global-palette-white-100);
  --dt-theme-text-text-80: var(--dt-global-palette-white-opacity-80);
  --dt-theme-text-text-70: var(--dt-global-palette-white-opacity-70);
  --dt-theme-text-text-50: var(--dt-global-palette-white-opacity-50);
  --dt-theme-text-text-30: var(--dt-global-palette-white-opacity-30);
  --dt-theme-text-text-10: var(--dt-global-palette-white-opacity-10);
  --dt-theme-surface-top-app-bar-top-app-bar: var(--dt-global-palette-grey-95);
  --dt-theme-surface-tooltip-tooltip: var(--dt-global-palette-grey-20);
  --dt-theme-surface-toggle-switch-field-toggle-switch-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-toggle-switch-toggle: var(--dt-global-palette-white-100);
  --dt-theme-surface-textarea-input-field-textarea-input-field-disabled: var(--dt-global-palette-grey-75);
  --dt-theme-surface-textarea-input-field-textarea-input-field-active: var(--dt-global-palette-grey-90);
  --dt-theme-surface-textarea-input-field-textarea-input-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-text-input-field-text-input-field-disabled: var(--dt-global-palette-grey-75);
  --dt-theme-surface-text-input-field-text-input-field-active: var(--dt-global-palette-grey-90);
  --dt-theme-surface-text-input-field-text-input-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-tabs-tabs-indicator: var(--dt-global-palette-pink-55);
  --dt-theme-surface-tabs-tabs: var(--dt-global-palette-grey-90);
  --dt-theme-surface-sticker-sticker-theatrical-release: var(--dt-global-palette-grey-85-opacity-60);
  --dt-theme-surface-sticker-sticker-subscribe: var(--dt-global-palette-pink-55);
  --dt-theme-surface-sticker-sticker-order: var(--dt-global-palette-white-100);
  --dt-theme-surface-sticker-sticker-offered-content: var(--dt-global-palette-white-100);
  --dt-theme-surface-sticker-sticker-live: var(--dt-global-palette-red-55);
  --dt-theme-surface-sticker-sticker-last-days: var(--dt-global-palette-red-55);
  --dt-theme-surface-sticker-sticker-get: var(--dt-global-palette-pink-55);
  --dt-theme-surface-sticker-sticker-free-to-air: var(--dt-global-palette-grey-85-opacity-60);
  --dt-theme-surface-sticker-sticker-duration: var(--dt-global-palette-grey-85-opacity-60);
  --dt-theme-surface-sticker-sticker-date: var(--dt-global-palette-grey-85-opacity-60);
  --dt-theme-surface-sticker-sticker-coming-soon: var(--dt-global-palette-grey-85-opacity-60);
  --dt-theme-surface-select-input-option-field-select-input-option-field-hover: var(--dt-global-palette-grey-75);
  --dt-theme-surface-select-input-option-field-select-input-option-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-select-input-field-select-input-field-disabled: var(--dt-global-palette-grey-75);
  --dt-theme-surface-select-input-field-select-input-field-active: var(--dt-global-palette-grey-90);
  --dt-theme-surface-select-input-field-select-input-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-segmented-control-segmented-control-active: var(--dt-global-palette-grey-75);
  --dt-theme-surface-segmented-control-segmented-control: var(--dt-global-palette-grey-90);
  --dt-theme-surface-season-selector-season-selector-description: var(--dt-global-palette-white-opacity-55);
  --dt-theme-surface-season-selector-season-selector-unselected: var(--dt-global-palette-grey-85);
  --dt-theme-surface-season-selector-season-selector-selected: var(--dt-global-palette-white-100);
  --dt-theme-surface-radio-button-field-radio-button-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-progressbar-statistics-progressbar-statistics-active: var(--dt-global-palette-red-55);
  --dt-theme-surface-progressbar-statistics-progressbar-statistics-default: var(--dt-global-palette-white-100);
  --dt-theme-surface-progressbar-progressbar-circle: var(--dt-global-palette-pink-55);
  --dt-theme-surface-progressbar-progressbar-live: var(--dt-global-palette-red-55);
  --dt-theme-surface-progressbar-progressbar-on-going: var(--dt-global-palette-white-100);
  --dt-theme-surface-profile-card-profile-card-back: var(--dt-global-palette-black-100);
  --dt-theme-surface-profile-card-profile-card: var(--dt-global-palette-grey-90);
  --dt-theme-surface-password-input-field-password-input-field-disabled: var(--dt-global-palette-grey-75);
  --dt-theme-surface-password-input-field-password-input-field-active: var(--dt-global-palette-grey-90);
  --dt-theme-surface-password-input-field-password-input-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-page-controls-dot-page-controls-inactive: var(--dt-global-palette-white-opacity-20);
  --dt-theme-surface-page-controls-dot-page-controls-active: var(--dt-global-palette-white-100);
  --dt-theme-surface-number-access-input-field-number-access-input-field-disabled: var(--dt-global-palette-grey-75);
  --dt-theme-surface-number-access-input-field-number-access-input-field-active: var(--dt-global-palette-grey-90);
  --dt-theme-surface-number-access-input-field-number-access-input-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-modal-modal: var(--dt-global-palette-grey-95);
  --dt-theme-surface-mm-yy-ccv-input-field-mm-yy-ccv-input-field-disabled: var(--dt-global-palette-grey-75);
  --dt-theme-surface-mm-yy-ccv-input-field-mm-yy-ccv-input-field-active: var(--dt-global-palette-grey-90);
  --dt-theme-surface-mm-yy-ccv-input-field-mm-yy-ccv-input-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-login-card-login-card: var(--dt-global-palette-grey-90);
  --dt-theme-surface-list-timeline-vote-list-timeline-vote-result: var(--dt-global-palette-grey-80);
  --dt-theme-surface-list-timeline-vote-list-timeline-vote-select: var(--dt-global-palette-pink-55);
  --dt-theme-surface-list-statistics-list-statistics: var(--dt-global-palette-grey-90);
  --dt-theme-surface-list-row-list-row-disabled: var(--dt-global-palette-grey-95);
  --dt-theme-surface-list-row-list-row-hover: var(--dt-global-palette-grey-75);
  --dt-theme-surface-list-row-list-row: var(--dt-global-palette-grey-90);
  --dt-theme-surface-list-ranking-list-ranking-highlight: var(--dt-global-palette-grey-85);
  --dt-theme-surface-list-ranking-list-ranking: var(--dt-global-palette-grey-90);
  --dt-theme-surface-input-field-alert-input-field-alert-info: var(--dt-global-palette-blue-30);
  --dt-theme-surface-input-field-alert-input-field-alert-success: var(--dt-global-palette-green-95);
  --dt-theme-surface-input-field-alert-input-field-alert-warning: var(--dt-global-palette-orange-95);
  --dt-theme-surface-input-field-alert-input-field-alert-error: var(--dt-global-palette-red-95);
  --dt-theme-surface-input-field-alert-input-field-alert: var(--dt-global-palette-grey-85);
  --dt-theme-surface-icon-footer-footer-hover: var(--dt-global-palette-black-opacity-80);
  --dt-theme-surface-icon-footer-footer: var(--dt-global-palette-grey-90);
  --dt-theme-surface-iban-input-field-iban-input-field-disabled: var(--dt-global-palette-grey-75);
  --dt-theme-surface-iban-input-field-iban-input-field-active: var(--dt-global-palette-grey-90);
  --dt-theme-surface-iban-input-field-iban-input-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-filter-tabs-filter-tabs-selected: var(--dt-global-palette-white-100);
  --dt-theme-surface-filter-tabs-filter-tabs-hover: var(--dt-global-palette-grey-10);
  --dt-theme-surface-filter-tabs-filter-tabs-default: var(--dt-global-palette-grey-85);
  --dt-theme-surface-dropdown-list-menu-dropdown-list-menu: var(--dt-global-palette-grey-90);
  --dt-theme-surface-credit-card-input-field-credit-card-input-field-disabled: var(--dt-global-palette-grey-75);
  --dt-theme-surface-credit-card-input-field-credit-card-input-field-active: var(--dt-global-palette-grey-90);
  --dt-theme-surface-credit-card-input-field-credit-card-input-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-civility-input-field-civility-input-field-disabled: var(--dt-global-palette-grey-75);
  --dt-theme-surface-civility-input-field-civility-input-field-active: var(--dt-global-palette-grey-85);
  --dt-theme-surface-civility-input-field-civility-input-field: var(--dt-global-palette-grey-75);
  --dt-theme-surface-checkbox-field-checkbox-field: var(--dt-global-palette-grey-90);
  --dt-theme-surface-checkbox-checkbox: var(--dt-global-palette-white-100);
  --dt-theme-surface-card-timeline-vote-versus-card-timeline-vote-versus-result: var(--dt-global-palette-grey-80);
  --dt-theme-surface-card-timeline-vote-versus-card-timeline-vote-versus-select: var(--dt-global-palette-pink-55);
  --dt-theme-surface-card-timeline-facts-card-timeline-facts: var(--dt-global-palette-grey-90);
  --dt-theme-surface-card-tab-card-tab-hover: var(--dt-global-palette-grey-80);
  --dt-theme-surface-card-ongoing-surface: var(--dt-global-palette-grey-10);
  --dt-theme-surface-card-upcoming-surface: var(--dt-global-palette-grey-10);
  --dt-theme-surface-card-item-countdown-v2-card-item-countdown: var(--dt-global-palette-yellow-50);
  --dt-theme-surface-card-item-advantages-card-item-advantages-price-tag-transparent: var(
    --dt-global-palette-black-opacity-0
  );
  --dt-theme-surface-card-item-advantages-card-item-advantages-promotion-transparent: var(
    --dt-global-palette-black-opacity-0
  );
  --dt-theme-surface-card-item-advantages-card-item-advantages-price-tag: var(--dt-global-palette-green-95);
  --dt-theme-surface-card-item-advantages-card-item-advantages-promotion: var(--dt-global-palette-blue-30);
  --dt-theme-surface-card-infobanner-card-infobanner: var(--dt-global-palette-grey-90);
  --dt-theme-surface-card-game-rating-card-game-rating: var(--dt-global-palette-grey-90);
  --dt-theme-surface-card-detailed-tile-card-detailed-tile: var(--dt-global-palette-grey-90);
  --dt-theme-surface-button-logo-surface-button-logo-bullets: var(--dt-global-palette-white-opacity-50);
  --dt-theme-surface-button-live-button-live-on-disabled: var(--dt-global-palette-red-70);
  --dt-theme-surface-button-live-button-live-on-active: var(--dt-global-palette-red-35);
  --dt-theme-surface-button-live-button-live-on-hover: var(--dt-global-palette-red-45);
  --dt-theme-surface-button-live-button-live-on-default: var(--dt-global-palette-red-55);
  --dt-theme-surface-button-tabs-button-tabs-selected: var(--dt-global-palette-grey-90);
  --dt-theme-surface-button-tabs-button-tabs-default: var(--dt-global-palette-white-opacity-0);
  --dt-theme-surface-bullet-carousel-indicator-bullet-carousel-indicator-inactive: var(
    --dt-global-palette-white-opacity-20
  );
  --dt-theme-surface-bullet-carousel-indicator-bullet-carousel-indicator-active: var(--dt-global-palette-white-100);
  --dt-theme-surface-bottom-app-bar-bottom-app-bar: var(--dt-global-palette-grey-90);
  --dt-theme-surface-alert-box-alert-box-info: var(--dt-global-palette-blue-30);
  --dt-theme-surface-alert-box-alert-box-success: var(--dt-global-palette-green-95);
  --dt-theme-surface-alert-box-alert-box-warning: var(--dt-global-palette-orange-95);
  --dt-theme-surface-alert-box-alert-box-error: var(--dt-global-palette-red-95);
  --dt-theme-surface-alert-box-alert-box: var(--dt-global-palette-grey-85);
  --dt-theme-surface-accordion-accordion: var(--dt-global-palette-grey-90);
  --dt-theme-surface-surface: var(--dt-global-palette-grey-90);
  --dt-theme-link-link-accent-pressed: var(--dt-global-palette-pink-70);
  --dt-theme-link-link-accent-hover: var(--dt-global-palette-pink-40);
  --dt-theme-link-link-accent: var(--dt-global-palette-pink-55);
  --dt-theme-link-link-pressed: var(--dt-global-palette-grey-25);
  --dt-theme-link-link-hover: var(--dt-global-palette-grey-10);
  --dt-theme-link-link-default: var(--dt-global-palette-grey-20);
  --dt-theme-icon-top-app-bar-icon-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-icon-top-app-bar-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-tooltip-tooltip-link-icon-pressed: var(--dt-global-palette-grey-90);
  --dt-theme-icon-tooltip-tooltip-link-icon-hover: var(--dt-global-palette-grey-75);
  --dt-theme-icon-tooltip-tooltip-link-icon: var(--dt-global-palette-grey-85);
  --dt-theme-icon-text-input-field-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-tabs-icon-accent: var(--dt-global-palette-pink-55);
  --dt-theme-icon-tabs-icon-active: var(--dt-global-palette-white-100);
  --dt-theme-icon-tabs-icon: var(--dt-global-palette-white-opacity-80);
  --dt-theme-icon-sticker-icon: var(--dt-global-palette-black-100);
  --dt-theme-icon-select-input-field-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-rating-icon-secondary-selected: var(--dt-global-palette-red-70);
  --dt-theme-icon-rating-icon-secondary-unselected: var(--dt-global-palette-white-opacity-20);
  --dt-theme-icon-rating-icon-primary-selected: var(--dt-global-palette-light-yellow-50);
  --dt-theme-icon-rating-icon-primary-unselected: var(--dt-global-palette-white-opacity-20);
  --dt-theme-icon-radio-button-radio-button-error: var(--dt-global-palette-red-35);
  --dt-theme-icon-radio-button-radio-button: var(--dt-global-palette-black-100);
  --dt-theme-icon-profile-card-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-password-input-field-icon-success: var(--dt-global-palette-green-50);
  --dt-theme-icon-password-input-field-icon-error: var(--dt-global-palette-red-35);
  --dt-theme-icon-password-input-field-icon-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-icon-password-input-field-icon-active: var(--dt-global-palette-white-100);
  --dt-theme-icon-password-input-field-icon: var(--dt-global-palette-white-opacity-55);
  --dt-theme-icon-offer-card-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-number-access-input-field-icon-success: var(--dt-global-palette-green-50);
  --dt-theme-icon-number-access-input-field-icon-error: var(--dt-global-palette-red-35);
  --dt-theme-icon-nav-button-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-modal-icon-accent: var(--dt-global-palette-pink-55);
  --dt-theme-icon-modal-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-mm-yy-ccv-input-field-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-media-block-button-icon-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-live-grid-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-list-timeline-vote-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-list-row-check-icon-accent-disabled: var(--dt-global-palette-pink-55-opacity-05);
  --dt-theme-icon-list-row-chevron-disabled: var(--dt-global-palette-white-opacity-30);
  --dt-theme-icon-list-row-chevron-price-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-icon-list-row-chevron-price: var(--dt-global-palette-white-100);
  --dt-theme-icon-list-row-chevron: var(--dt-global-palette-white-opacity-70);
  --dt-theme-icon-list-row-link-icon: var(--dt-global-palette-grey-10);
  --dt-theme-icon-list-row-check-icon-accent: var(--dt-global-palette-pink-55);
  --dt-theme-icon-list-row-check-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-link-link-icon-accent-pressed: var(--dt-global-palette-pink-70);
  --dt-theme-icon-link-link-icon-accent-hover: var(--dt-global-palette-pink-40);
  --dt-theme-icon-link-link-icon-accent: var(--dt-global-palette-pink-55);
  --dt-theme-icon-link-link-icon-pressed: var(--dt-global-palette-grey-25);
  --dt-theme-icon-link-link-icon-hover: var(--dt-global-palette-grey-10);
  --dt-theme-icon-link-link-icon: var(--dt-global-palette-grey-20);
  --dt-theme-icon-input-field-alert-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-iban-input-field-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-header-icon-active: var(--dt-global-palette-pink-55);
  --dt-theme-icon-header-icon-disabled: var(--dt-global-palette-grey-20);
  --dt-theme-icon-header-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-footer-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-filter-tabs-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-episode-list-check-icon: var(--dt-global-palette-pink-55);
  --dt-theme-icon-download-list-icon-native: var(--dt-global-palette-white-opacity-15);
  --dt-theme-icon-download-list-icon-chevron: var(--dt-global-palette-grey-10);
  --dt-theme-icon-download-list-icon-accent: var(--dt-global-palette-pink-55);
  --dt-theme-icon-download-list-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-credit-card-input-field-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-civility-input-field-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-checkbox-icon-error: var(--dt-global-palette-red-35);
  --dt-theme-icon-checkbox-icon: var(--dt-global-palette-black-100);
  --dt-theme-icon-carousel-indicator-icon-inactive: var(--dt-global-palette-white-opacity-20);
  --dt-theme-icon-carousel-indicator-icon-active: var(--dt-global-palette-white-100);
  --dt-theme-icon-card-timeline-vote-versus-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-card-tile-card-tile-icon-accent: var(--dt-global-palette-pink-55);
  --dt-theme-icon-card-item-countdown-v2-card-item-countdown: var(--dt-global-palette-black-100);
  --dt-theme-icon-card-item-countdown-card-item-countdown: var(--dt-global-palette-pink-55);
  --dt-theme-icon-card-item-advantages-card-item-advantages-price-tag: var(--dt-global-palette-green-50);
  --dt-theme-icon-card-item-advantages-card-item-advantages-promotion-tag: var(--dt-global-palette-blue-10);
  --dt-theme-icon-card-infobanner-card-infobanner-icon-accent: var(--dt-global-palette-pink-55);
  --dt-theme-icon-card-item-alert-card-item-alert-active-hover: var(--dt-global-palette-pink-40);
  --dt-theme-icon-card-item-alert-card-item-alert-active: var(--dt-global-palette-pink-55);
  --dt-theme-icon-card-item-alert-card-item-alert-hover: var(--dt-global-palette-grey-65);
  --dt-theme-icon-card-item-alert-card-item-alert-default: var(--dt-global-palette-black-100);
  --dt-theme-icon-button-icon-video-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-icon-mood-icon-bad: var(--dt-global-palette-red-35);
  --dt-theme-icon-button-icon-mood-icon-good: var(--dt-global-palette-green-50);
  --dt-theme-icon-button-icon-mood-icon-neutral: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-tertiary-diabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-tertiary-active: var(--dt-global-palette-grey-40);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-tertiary-hover: var(--dt-global-palette-grey-30);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-tertiary: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-secondary-diabled: var(--dt-global-palette-grey-85-opacity-50);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-secondary-active: var(--dt-global-palette-grey-90);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-secondary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-secondary: var(--dt-global-palette-grey-85);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-primary-disabled: var(--dt-global-palette-pink-55-opacity-50);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-primary-active: var(--dt-global-palette-pink-70);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-primary-hover: var(--dt-global-palette-pink-40);
  --dt-theme-icon-button-icon-v2-button-icon-ghost-primary: var(--dt-global-palette-pink-55);
  --dt-theme-icon-button-icon-v2-button-icon-filled-quinary-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-icon-button-icon-v2-button-icon-filled-quinary: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-icon-v2-button-icon-filled-quaternary-disabled: var(--dt-global-palette-grey-10);
  --dt-theme-icon-button-icon-v2-button-icon-filled-quaternary: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-icon-v2-button-icon-filled-tertiary-disabled: var(--dt-global-palette-black-opacity-50);
  --dt-theme-icon-button-icon-v2-button-icon-filled-tertiary: var(--dt-global-palette-black-100);
  --dt-theme-icon-button-icon-v2-button-icon-filled-secondary-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-icon-button-icon-v2-button-icon-filled-secondary: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-icon-v2-button-icon-filled-primary-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-icon-button-icon-v2-button-icon-filled-primary: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-icon-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-v2-button-fit-tertiary-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-icon-button-v2-button-fit-tertiary-active: var(--dt-global-palette-grey-25);
  --dt-theme-icon-button-v2-button-fit-tertiary-hover: var(--dt-global-palette-grey-10);
  --dt-theme-icon-button-v2-button-fit-tertiary: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-v2-button-fit-secondary-disabled: var(--dt-global-palette-grey-85-opacity-50);
  --dt-theme-icon-button-v2-button-fit-secondary-active: var(--dt-global-palette-grey-90);
  --dt-theme-icon-button-v2-button-fit-secondary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-icon-button-v2-button-fit-secondary: var(--dt-global-palette-grey-85);
  --dt-theme-icon-button-v2-button-fit-primary-disabled: var(--dt-global-palette-pink-55-opacity-50);
  --dt-theme-icon-button-v2-button-fit-primary-active: var(--dt-global-palette-pink-70);
  --dt-theme-icon-button-v2-button-fit-primary-hover: var(--dt-global-palette-pink-40);
  --dt-theme-icon-button-v2-button-fit-primary: var(--dt-global-palette-pink-55);
  --dt-theme-icon-button-v2-button-ghost-tertiary-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-icon-button-v2-button-ghost-tertiary-active: var(--dt-global-palette-grey-25);
  --dt-theme-icon-button-v2-button-ghost-tertiary-hover: var(--dt-global-palette-grey-10);
  --dt-theme-icon-button-v2-button-ghost-tertiary: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-v2-button-ghost-secondary-disabled: var(--dt-global-palette-grey-85-opacity-50);
  --dt-theme-icon-button-v2-button-ghost-secondary-active: var(--dt-global-palette-grey-90);
  --dt-theme-icon-button-v2-button-ghost-secondary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-icon-button-v2-button-ghost-secondary: var(--dt-global-palette-grey-85);
  --dt-theme-icon-button-v2-button-ghost-primary-disabled: var(--dt-global-palette-pink-55-opacity-50);
  --dt-theme-icon-button-v2-button-ghost-primary-active: var(--dt-global-palette-pink-70);
  --dt-theme-icon-button-v2-button-ghost-primary-hover: var(--dt-global-palette-pink-40);
  --dt-theme-icon-button-v2-button-ghost-primary: var(--dt-global-palette-pink-55);
  --dt-theme-icon-button-v2-button-filled-tertiary-disabled: var(--dt-global-palette-black-opacity-50);
  --dt-theme-icon-button-v2-button-filled-tertiary: var(--dt-global-palette-black-100);
  --dt-theme-icon-button-v2-button-filled-secondary-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-icon-button-v2-button-filled-secondary: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-v2-button-filled-primary-disabled: var(--dt-global-palette-white-opacity-50);
  --dt-theme-icon-button-v2-button-filled-primary: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-text-icon-secondary-pressed: var(--dt-global-palette-grey-90);
  --dt-theme-icon-button-text-icon-secondary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-icon-button-text-icon-secondary-default: var(--dt-global-palette-grey-85);
  --dt-theme-icon-button-text-icon-accent-pressed: var(--dt-global-palette-pink-70);
  --dt-theme-icon-button-text-icon-accent-hover: var(--dt-global-palette-pink-40);
  --dt-theme-icon-button-text-icon-accent-default: var(--dt-global-palette-pink-55);
  --dt-theme-icon-button-tertiary-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-secondary-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-button-primary-icon-accent-pressed: var(--dt-global-palette-pink-70);
  --dt-theme-icon-button-primary-icon-accent-hover: var(--dt-global-palette-pink-40);
  --dt-theme-icon-button-primary-icon-accent: var(--dt-global-palette-pink-55);
  --dt-theme-icon-button-primary-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-bottom-app-bar-icon-accent: var(--dt-global-palette-pink-55);
  --dt-theme-icon-bottom-app-bar-icon: var(--dt-global-palette-white-opacity-80);
  --dt-theme-icon-avatar-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-avatar-icon-profile: var(--dt-global-palette-black-100);
  --dt-theme-icon-alert-box-icon: var(--dt-global-palette-white-100);
  --dt-theme-icon-activation-card-activation-card: var(--dt-global-palette-black-100);
  --dt-theme-icon-icon-accent: var(--dt-global-palette-pink-55);
  --dt-theme-icon-icon: var(--dt-global-palette-white-100);
  --dt-theme-border-toggle-switch-field-toggle-switch-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-toggle-switch-field-toggle-switch-field: var(--dt-global-palette-grey-80);
  --dt-theme-border-textarea-input-field-textarea-input-field-cursor: var(--dt-global-palette-pink-55);
  --dt-theme-border-textarea-input-field-textarea-input-field-disabled: var(--dt-global-palette-grey-80);
  --dt-theme-border-textarea-input-field-textarea-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-textarea-input-field-textarea-input-field-default: var(--dt-global-palette-grey-80);
  --dt-theme-border-text-input-field-text-input-field-cursor: var(--dt-global-palette-pink-55);
  --dt-theme-border-text-input-field-text-input-field-success-disabled: var(--dt-global-palette-green-50);
  --dt-theme-border-text-input-field-text-input-field-success-active: var(--dt-global-palette-green-50);
  --dt-theme-border-text-input-field-text-input-field-success-default: var(--dt-global-palette-green-50);
  --dt-theme-border-text-input-field-text-input-field-warning-disabled: var(--dt-global-palette-orange-50);
  --dt-theme-border-text-input-field-text-input-field-warning-active: var(--dt-global-palette-orange-50);
  --dt-theme-border-text-input-field-text-input-field-warning-default: var(--dt-global-palette-orange-50);
  --dt-theme-border-text-input-field-text-input-field-error-disabled: var(--dt-global-palette-red-35);
  --dt-theme-border-text-input-field-text-input-field-error-active: var(--dt-global-palette-red-35);
  --dt-theme-border-text-input-field-text-input-field-error-default: var(--dt-global-palette-red-35);
  --dt-theme-border-text-input-field-text-input-field-disabled: var(--dt-global-palette-grey-80);
  --dt-theme-border-text-input-field-text-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-text-input-field-text-input-field-default: var(--dt-global-palette-grey-80);
  --dt-theme-border-tabs-underline: var(--dt-global-palette-pink-55);
  --dt-theme-border-select-input-option-field-select-input-option-field-hover: var(--dt-global-palette-grey-80);
  --dt-theme-border-select-input-option-field-select-input-option-field-default: var(--dt-global-palette-grey-80);
  --dt-theme-border-select-input-field-select-input-field-cursor: var(--dt-global-palette-pink-55);
  --dt-theme-border-select-input-field-select-input-field-error-active: var(--dt-global-palette-red-35);
  --dt-theme-border-select-input-field-select-input-field-error-default: var(--dt-global-palette-red-35);
  --dt-theme-border-select-input-field-select-input-field-disabled: var(--dt-global-palette-grey-80);
  --dt-theme-border-select-input-field-select-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-select-input-field-select-input-field-default: var(--dt-global-palette-grey-80);
  --dt-theme-border-segmented-control-segmented-control: var(--dt-global-palette-black-opacity-05);
  --dt-theme-border-radio-button-field-radio-button-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-radio-button-field-radio-button-field: var(--dt-global-palette-grey-80);
  --dt-theme-border-radio-button-radio-button-error: var(--dt-global-palette-red-35);
  --dt-theme-border-radio-button-radio-button: var(--dt-global-palette-white-100);
  --dt-theme-border-profile-card-divider: var(--dt-global-palette-grey-85);
  --dt-theme-border-password-input-field-password-input-field-cursor: var(--dt-global-palette-pink-55);
  --dt-theme-border-password-input-field-password-input-field-error-active: var(--dt-global-palette-red-35);
  --dt-theme-border-password-input-field-password-input-field-error-default: var(--dt-global-palette-red-35);
  --dt-theme-border-password-input-field-password-input-field-disabled: var(--dt-global-palette-grey-80);
  --dt-theme-border-password-input-field-password-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-password-input-field-password-input-field-default: var(--dt-global-palette-grey-80);
  --dt-theme-border-offer-card-offer-card: var(--dt-global-palette-grey-25);
  --dt-theme-border-offer-tag-offer-tag: var(--dt-global-palette-white-100);
  --dt-theme-border-number-access-input-field-number-access-input-field-success-default: var(
    --dt-global-palette-green-50
  );
  --dt-theme-border-number-access-input-field-number-access-input-field-error-default: var(--dt-global-palette-red-35);
  --dt-theme-border-number-access-input-field-number-access-input-field-disabled: var(--dt-global-palette-grey-85);
  --dt-theme-border-number-access-input-field-number-access-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-number-access-input-field-number-access-input-field-default: var(--dt-global-palette-grey-85);
  --dt-theme-border-mm-yy-ccv-input-field-mm-yy-ccv-input-field-cursor: var(--dt-global-palette-pink-55);
  --dt-theme-border-mm-yy-ccv-input-field-mm-yy-ccv-input-field-error-active: var(--dt-global-palette-red-35);
  --dt-theme-border-mm-yy-ccv-input-field-mm-yy-ccv-input-field-error-default: var(--dt-global-palette-red-35);
  --dt-theme-border-mm-yy-ccv-input-field-mm-yy-ccv-input-field-disabled: var(--dt-global-palette-grey-80);
  --dt-theme-border-mm-yy-ccv-input-field-mm-yy-ccv-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-mm-yy-ccv-input-field-mm-yy-ccv-input-field-default: var(--dt-global-palette-grey-80);
  --dt-theme-border-login-card-divider: var(--dt-global-palette-grey-85);
  --dt-theme-border-list-timeline-vote-list-timeline-vote: var(--dt-global-palette-grey-80);
  --dt-theme-border-list-statistics-list-statistics: var(--dt-global-palette-grey-80);
  --dt-theme-border-list-row-divider: var(--dt-global-palette-grey-85);
  --dt-theme-border-list-row-list-row: var(--dt-global-palette-black-opacity-0);
  --dt-theme-border-list-ranking-list-ranking: var(--dt-global-palette-grey-80);
  --dt-theme-border-iban-input-field-iban-input-field-cursor: var(--dt-global-palette-pink-55);
  --dt-theme-border-iban-input-field-iban-input-field-error-active: var(--dt-global-palette-red-35);
  --dt-theme-border-iban-input-field-iban-input-field-error-default: var(--dt-global-palette-red-35);
  --dt-theme-border-iban-input-field-iban-input-field-disabled: var(--dt-global-palette-grey-80);
  --dt-theme-border-iban-input-field-iban-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-iban-input-field-iban-input-field-default: var(--dt-global-palette-grey-80);
  --dt-theme-border-header-header: var(--dt-global-palette-grey-85);
  --dt-theme-border-footer-footer-divider: var(--dt-global-palette-grey-80);
  --dt-theme-border-footer-footer: var(--dt-global-palette-grey-85);
  --dt-theme-border-credit-card-input-field-credit-card-input-field-cursor: var(--dt-global-palette-pink-55);
  --dt-theme-border-credit-card-input-field-credit-card-input-field-error-active: var(--dt-global-palette-red-35);
  --dt-theme-border-credit-card-input-field-credit-card-input-field-error-default: var(--dt-global-palette-red-35);
  --dt-theme-border-credit-card-input-field-credit-card-input-field-disabled: var(--dt-global-palette-grey-80);
  --dt-theme-border-credit-card-input-field-credit-card-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-credit-card-input-field-credit-card-input-field-default: var(--dt-global-palette-grey-80);
  --dt-theme-border-civility-input-field-civility-input-field-error-divider: var(--dt-global-palette-red-35);
  --dt-theme-border-civility-input-field-civility-input-field-error-default: var(--dt-global-palette-red-35);
  --dt-theme-border-civility-input-field-civility-input-field-divider: var(--dt-global-palette-grey-85);
  --dt-theme-border-civility-input-field-civility-input-field-disabled: var(--dt-global-palette-grey-80);
  --dt-theme-border-civility-input-field-civility-input-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-civility-input-field-civility-input-field-default: var(--dt-global-palette-grey-80);
  --dt-theme-border-checkbox-field-checkbox-field-active: var(--dt-global-palette-white-100);
  --dt-theme-border-checkbox-field-checkbox-field: var(--dt-global-palette-grey-80);
  --dt-theme-border-checkbox-checkbox-error: var(--dt-global-palette-red-35);
  --dt-theme-border-checkbox-checkbox: var(--dt-global-palette-white-100);
  --dt-theme-border-card-infobanner-card-infobanner: var(--dt-global-palette-black-opacity-0);
  --dt-theme-border-button-tabs-button-tabs-selected: var(--dt-global-palette-pink-55);
  --dt-theme-border-button-tabs-button-tabs-default: var(--dt-global-palette-grey-85);
  --dt-theme-border-button-live-button-live-startover-disabled: var(--dt-global-palette-grey-55);
  --dt-theme-border-button-live-button-live-startover-active: var(--dt-global-palette-grey-25);
  --dt-theme-border-button-live-button-live-startover-hover: var(--dt-global-palette-grey-40);
  --dt-theme-border-button-live-button-live-startover-default: var(--dt-global-palette-white-100);
  --dt-theme-border-button-icon-v2-button-icon-filled-quinary-disabled: var(--dt-global-palette-white-opacity-10);
  --dt-theme-border-button-icon-v2-button-icon-filled-quinary-active: var(--dt-global-palette-white-100);
  --dt-theme-border-button-icon-v2-button-icon-filled-quinary-hover: var(--dt-global-palette-grey-60);
  --dt-theme-border-button-icon-v2-button-icon-filled-quinary: var(--dt-global-palette-grey-65);
  --dt-theme-border-button-icon-v2-button-icon-filled-quaternary-disabled: var(--dt-global-palette-white-opacity-10);
  --dt-theme-border-button-icon-v2-button-icon-filled-quaternary-active: var(--dt-global-palette-white-100);
  --dt-theme-border-button-icon-v2-button-icon-filled-quaternary-hover: var(--dt-global-palette-grey-55);
  --dt-theme-border-button-icon-v2-button-icon-filled-quaternary: var(--dt-global-palette-grey-60);
  --dt-theme-border-button-icon-v2-button-icon-filled-tertiary-disabled: var(--dt-global-palette-black-opacity-10);
  --dt-theme-border-button-icon-v2-button-icon-filled-tertiary-active: var(--dt-global-palette-black-100);
  --dt-theme-border-button-icon-v2-button-icon-filled-tertiary-hover: var(--dt-global-palette-grey-30);
  --dt-theme-border-button-icon-v2-button-icon-filled-tertiary: var(--dt-global-palette-grey-35);
  --dt-theme-border-button-icon-v2-button-icon-filled-secondary-disabled: var(--dt-global-palette-white-opacity-10);
  --dt-theme-border-button-icon-v2-button-icon-filled-secondary-active: var(--dt-global-palette-white-100);
  --dt-theme-border-button-icon-v2-button-icon-filled-secondary-hover: var(--dt-global-palette-grey-65);
  --dt-theme-border-button-icon-v2-button-icon-filled-secondary: var(--dt-global-palette-grey-70);
  --dt-theme-border-button-icon-v2-button-icon-filled-primary-disabled: var(--dt-global-palette-white-opacity-10);
  --dt-theme-border-button-icon-v2-button-icon-filled-primary-active: var(--dt-global-palette-white-100);
  --dt-theme-border-button-icon-v2-button-icon-filled-primary-hover: var(--dt-global-palette-pink-30);
  --dt-theme-border-button-icon-v2-button-icon-filled-primary: var(--dt-global-palette-pink-55);
  --dt-theme-border-button-icon-secondary-button-icon-secondary: var(--dt-global-palette-white-opacity-20);
  --dt-theme-border-activation-card-activation-card: var(--dt-global-palette-grey-20);
  --dt-theme-border-accordion-accordion-divider: var(--dt-global-palette-grey-85);
  --dt-theme-border-accordion-accordion: var(--dt-global-palette-black-opacity-0);
  --dt-theme-border-border-info: var(--dt-global-palette-blue-10);
  --dt-theme-border-border-success: var(--dt-global-palette-green-50);
  --dt-theme-border-border-warning: var(--dt-global-palette-orange-50);
  --dt-theme-border-border-error: var(--dt-global-palette-red-35);
  --dt-theme-border-border-live: var(--dt-global-palette-red-55);
  --dt-theme-border-border-accent: var(--dt-global-palette-pink-55);
  --dt-theme-border-border-active: var(--dt-global-palette-white-100);
  --dt-theme-border-border-opaque: var(--dt-global-palette-grey-80);
  --dt-theme-border-border: var(--dt-global-palette-grey-85);
  --dt-theme-background-toggle-switch-toggle-switch-off: var(--dt-global-palette-grey-25);
  --dt-theme-background-toggle-switch-toggle-switch-on: var(--dt-global-palette-pink-55);
  --dt-theme-background-tabs-tabs: var(--dt-global-palette-grey-95);
  --dt-theme-background-radio-button-radio-button: var(--dt-global-palette-grey-10);
  --dt-theme-background-progressbar-progressbar-forced-dark: var(--dt-global-palette-white-opacity-10);
  --dt-theme-background-progressbar-progressbar: var(--dt-global-palette-white-opacity-10);
  --dt-theme-background-profile-card-profile-card: var(--dt-global-palette-black-opacity-30);
  --dt-theme-background-profile-card-profile-card-icon: var(--dt-global-palette-grey-85);
  --dt-theme-background-password-input-field-bullet: var(--dt-global-palette-white-opacity-80);
  --dt-theme-background-offer-card-offer-card-icon: var(--dt-global-palette-green-50);
  --dt-theme-background-number-access-input-field-bullet: var(--dt-global-palette-white-100);
  --dt-theme-background-load-media-placeholder-load-media-placeholder: var(--dt-global-palette-grey-85);
  --dt-theme-background-list-timeline-vote-list-timeline-vote-success: var(--dt-global-palette-green-50);
  --dt-theme-background-list-timeline-vote-list-timeline-vote-default: var(--dt-global-palette-grey-90);
  --dt-theme-background-list-row-check: var(--dt-global-palette-grey-90);
  --dt-theme-background-icon-nav-button-icon-nav-button: var(--dt-global-palette-black-opacity-60);
  --dt-theme-background-icon-live-grid-icon-live-grid: var(--dt-global-palette-grey-85);
  --dt-theme-background-icon-input-field-alert-icon-input-field-alert-info: var(--dt-global-palette-blue-10);
  --dt-theme-background-icon-input-field-alert-icon-input-field-alert-success: var(--dt-global-palette-green-50);
  --dt-theme-background-icon-input-field-alert-icon-input-field-alert-warning: var(--dt-global-palette-orange-50);
  --dt-theme-background-icon-input-field-alert-icon-input-field-alert-error: var(--dt-global-palette-red-35);
  --dt-theme-background-icon-input-field-alert-icon-input-field-alert: var(--dt-global-palette-grey-95);
  --dt-theme-background-icon-alert-box-alert-box-info: var(--dt-global-palette-blue-10);
  --dt-theme-background-icon-alert-box-alert-box-success: var(--dt-global-palette-green-50);
  --dt-theme-background-icon-alert-box-alert-box-warning: var(--dt-global-palette-orange-50);
  --dt-theme-background-icon-alert-box-alert-box-error: var(--dt-global-palette-red-35);
  --dt-theme-background-icon-alert-box-alert-box: var(--dt-global-palette-neutral-50);
  --dt-theme-background-icon-rating-tertiary: var(--dt-global-palette-red-70);
  --dt-theme-background-icon-download-on: var(--dt-global-palette-pink-55);
  --dt-theme-background-icon-upgrade: var(--dt-global-palette-green-50);
  --dt-theme-background-icon-add-filled: var(--dt-global-palette-grey-85);
  --dt-theme-background-icon-error-download: var(--dt-global-palette-pink-55);
  --dt-theme-background-icon-error: var(--dt-global-palette-red-35);
  --dt-theme-background-icon-info-message: var(--dt-global-palette-grey-85);
  --dt-theme-background-icon-info-default: var(--dt-global-palette-grey-45);
  --dt-theme-background-icon-info: var(--dt-global-palette-blue-10);
  --dt-theme-background-icon-alert: var(--dt-global-palette-orange-50);
  --dt-theme-background-icon-valid: var(--dt-global-palette-green-50);
  --dt-theme-background-icon-play-button: var(--dt-global-palette-grey-85-opacity-70);
  --dt-theme-background-icon-start-over: var(--dt-global-palette-grey-80);
  --dt-theme-background-icon-edit: var(--dt-global-palette-grey-35);
  --dt-theme-background-icon-kids: var(--dt-global-palette-blue-10);
  --dt-theme-background-header-header: var(--dt-global-palette-grey-95);
  --dt-theme-background-footer-footer: var(--dt-global-palette-grey-95);
  --dt-theme-background-episode-list-episode-list-unavailable: var(--dt-global-palette-black-opacity-50);
  --dt-theme-background-episode-list-episode-list-icon-check: var(--dt-global-palette-white-100);
  --dt-theme-background-civility-input-field-icon-error: var(--dt-global-palette-red-35);
  --dt-theme-background-civility-input-field-hint-error: var(--dt-global-palette-red-35-opacity-20);
  --dt-theme-background-checkbox-checkbox: var(--dt-global-palette-grey-90);
  --dt-theme-background-card-timeline-vote-versus-card-timeline-vote-versus-success: var(--dt-global-palette-green-50);
  --dt-theme-background-card-timeline-vote-versus-card-timeline-vote-versus-default: var(--dt-global-palette-grey-90);
  --dt-theme-background-button-logo-button-logo-hover: var(--dt-global-palette-grey-75);
  --dt-theme-background-button-logo-button-logo: var(--dt-global-palette-grey-85);
  --dt-theme-background-button-icon-video-button-icon-video: var(--dt-global-palette-grey-85);
  --dt-theme-background-button-icon-mood-button-icon-mood-hover: var(--dt-global-palette-grey-65);
  --dt-theme-background-button-icon-mood-button-icon-mood-default: var(--dt-global-palette-grey-80);
  --dt-theme-background-button-icon-media-block-button-icon-media-block: var(--dt-global-palette-black-opacity-50);
  --dt-theme-background-button-icon-close-button-icon-close: var(--dt-global-palette-grey-85-opacity-80);
  --dt-theme-background-button-icon-back-button-icon-back: var(--dt-global-palette-grey-85-opacity-80);
  --dt-theme-background-button-icon-v2-button-icon-filled-quinary-disabled: var(--dt-global-palette-grey-80-opacity-50);
  --dt-theme-background-button-icon-v2-button-icon-filled-quinary-active: var(--dt-global-palette-grey-85);
  --dt-theme-background-button-icon-v2-button-icon-filled-quinary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-background-button-icon-v2-button-icon-filled-quinary: var(--dt-global-palette-grey-80);
  --dt-theme-background-button-icon-v2-button-icon-filled-quaternary-disabled: var(
    --dt-global-palette-grey-85-opacity-40
  );
  --dt-theme-background-button-icon-v2-button-icon-filled-quaternary-active: var(--dt-global-palette-grey-75);
  --dt-theme-background-button-icon-v2-button-icon-filled-quaternary-hover: var(--dt-global-palette-grey-65);
  --dt-theme-background-button-icon-v2-button-icon-filled-quaternary: var(--dt-global-palette-grey-85-opacity-80);
  --dt-theme-background-button-icon-v2-button-icon-filled-tertiary-disabled: var(
    --dt-global-palette-grey-20-opacity-50
  );
  --dt-theme-background-button-icon-v2-button-icon-filled-tertiary-active: var(--dt-global-palette-grey-25);
  --dt-theme-background-button-icon-v2-button-icon-filled-tertiary-hover: var(--dt-global-palette-grey-10);
  --dt-theme-background-button-icon-v2-button-icon-filled-tertiary: var(--dt-global-palette-grey-20);
  --dt-theme-background-button-icon-v2-button-icon-filled-secondary-disabled: var(
    --dt-global-palette-grey-85-opacity-50
  );
  --dt-theme-background-button-icon-v2-button-icon-filled-secondary-active: var(--dt-global-palette-grey-90);
  --dt-theme-background-button-icon-v2-button-icon-filled-secondary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-background-button-icon-v2-button-icon-filled-secondary: var(--dt-global-palette-grey-85);
  --dt-theme-background-button-icon-v2-button-icon-filled-primary-disabled: var(--dt-global-palette-pink-55-opacity-50);
  --dt-theme-background-button-icon-v2-button-icon-filled-primary-active: var(--dt-global-palette-pink-70);
  --dt-theme-background-button-icon-v2-button-icon-filled-primary-hover: var(--dt-global-palette-pink-40);
  --dt-theme-background-button-icon-v2-button-icon-filled-primary: var(--dt-global-palette-pink-55);
  --dt-theme-background-button-icon-button-icon-tertiary: var(--dt-global-palette-black-opacity-50);
  --dt-theme-background-button-icon-button-icon-secondary: var(--dt-global-palette-grey-85);
  --dt-theme-background-button-icon-button-icon-primary-hover: var(--dt-global-palette-grey-65);
  --dt-theme-background-button-icon-button-icon-primary: var(--dt-global-palette-grey-80);
  --dt-theme-background-button-v2-button-filled-tertiary-disabled: var(--dt-global-palette-grey-20-opacity-50);
  --dt-theme-background-button-v2-button-filled-tertiary-active: var(--dt-global-palette-grey-25);
  --dt-theme-background-button-v2-button-filled-tertiary-hover: var(--dt-global-palette-grey-10);
  --dt-theme-background-button-v2-button-filled-tertiary: var(--dt-global-palette-grey-20);
  --dt-theme-background-button-v2-button-filled-secondary-disabled: var(--dt-global-palette-grey-85-opacity-50);
  --dt-theme-background-button-v2-button-filled-secondary-active: var(--dt-global-palette-grey-90);
  --dt-theme-background-button-v2-button-filled-secondary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-background-button-v2-button-filled-secondary: var(--dt-global-palette-grey-85);
  --dt-theme-background-button-v2-button-filled-primary-disabled: var(--dt-global-palette-pink-55-opacity-50);
  --dt-theme-background-button-v2-button-filled-primary-active: var(--dt-global-palette-pink-70);
  --dt-theme-background-button-v2-button-filled-primary-hover: var(--dt-global-palette-pink-40);
  --dt-theme-background-button-v2-button-filled-primary: var(--dt-global-palette-pink-55);
  --dt-theme-background-button-tertiary-tertiary-pressed: var(--dt-global-palette-grey-90);
  --dt-theme-background-button-tertiary-tertiary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-background-button-tertiary-tertiary: var(--dt-global-palette-grey-85);
  --dt-theme-background-button-secondary-secondary-pressed: var(--dt-global-palette-grey-90);
  --dt-theme-background-button-secondary-secondary-hover: var(--dt-global-palette-grey-75);
  --dt-theme-background-button-secondary-secondary: var(--dt-global-palette-grey-85);
  --dt-theme-background-button-primary-accent-pressed: var(--dt-global-palette-pink-70);
  --dt-theme-background-button-primary-accent-hover: var(--dt-global-palette-pink-40);
  --dt-theme-background-button-primary-accent: var(--dt-global-palette-pink-55);
  --dt-theme-background-avatar-avatar-profile: var(--dt-global-palette-white-100);
  --dt-theme-background-avatar-avatar: var(--dt-global-palette-grey-90);
  --dt-theme-background-background: var(--dt-global-palette-grey-95);
  --dt-theme-brand-semantic-info: var(--dt-global-palette-blue-10);
  --dt-theme-brand-semantic-success: var(--dt-global-palette-green-50);
  --dt-theme-brand-semantic-warning: var(--dt-global-palette-orange-50);
  --dt-theme-brand-semantic-error: var(--dt-global-palette-red-35);
  --dt-theme-brand-context-live: var(--dt-global-palette-red-55);
  --dt-theme-brand-secondary-docs: var(--dt-global-palette-violet-10);
  --dt-theme-brand-secondary-series: var(--dt-global-palette-red-40);
  --dt-theme-brand-secondary-sport: var(--dt-global-palette-yellow-green-10);
  --dt-theme-brand-secondary-kids: var(--dt-global-palette-light-yellow-50);
  --dt-theme-brand-secondary-cinema: var(--dt-global-palette-blue-20);
  --dt-theme-brand-primary-accent: var(--dt-global-palette-pink-55);
}
